<template>
  <div class="main-content q-pa-md">
    <Mural :breadcrumbs="breadcrumbs" route="company" :query="{}">
      <DefaultBtn titleButton="Salvar" icon="edit" @click="saveCompany" />
    </Mural>
    <div class="content-new-training flex row col-12 lesson-content">
      <div class="content-form-training col-12">
        <form>
          <div class="col-6 flex row q-pa-sm">
            <div class="form-input col-6 column">
              <div class="row">
                <span class="title-input-form"
                  >Nome
                  <b title="Obrigatório" style="cursor: pointer">*</b></span
                >
              </div>
              <div class="row">
                <q-input
                  class="col-11"
                  bg-color="grey-3"
                  v-model="formCompany.company_name"
                  color="default-pink"
                  dense
                  type="text"
                  outlined
                  :rules="[(val) => !!val || 'O campo nome é obrigatório.']"
                />
              </div>
            </div>
            <div class="form-input col-6 column">
              <div class="row">
                <span class="title-input-form"
                  >CNPJ
                  <b title="Obrigatório" style="cursor: pointer">*</b></span
                >
              </div>
              <div class="row">
                <q-input
                  class="col-11"
                  bg-color="grey-3"
                  v-model="formCompany.cnpj"
                  color="default-pink"
                  dense
                  type="text"
                  outlined
                  :rules="[(val) => !!val || 'O campo cnpj é obrigatório.']"
                />
              </div>
            </div>

            <div class="form-input col-6 column">
              <div class="row">
                <span class="title-input-form"
                  >Telefone
                  <b title="Obrigatório" style="cursor: pointer">*</b></span
                >
              </div>
              <div class="row">
                <q-input
                  class="col-11"
                  bg-color="grey-3"
                  v-model="formCompany.phone"
                  color="default-pink"
                  dense
                  type="text"
                  outlined
                  :rules="[(val) => !!val || 'O campo telefone é obrigatório.']"
                />
              </div>
            </div>
            <div class="form-input col-6 column">
              <div class="row">
                <span class="title-input-form"
                  >Email
                  <b title="Obrigatório" style="cursor: pointer">*</b></span
                >
              </div>
              <div class="row">
                <q-input
                  class="col-11"
                  bg-color="grey-3"
                  v-model="formCompany.email"
                  color="default-pink"
                  dense
                  type="text"
                  outlined
                  :rules="[(val) => !!val || 'O campo email é obrigatório.']"
                />
              </div>
            </div>

            <div class="form-input col-6 column">
              <div class="row">
                <span class="title-input-form"
                  >Nome Corporativo
                  <b title="Obrigatório" style="cursor: pointer">*</b></span
                >
              </div>
              <div class="row">
                <q-input
                  class="col-11"
                  bg-color="grey-3"
                  v-model="formCompany.corporate_name"
                  color="default-pink"
                  dense
                  type="text"
                  outlined
                  :rules="[(val) => !!val || 'O campo é obrigatório.']"
                />
              </div>
            </div>

<!--             <div class="form-input flex justify-center col-6 column">
              <OutlineBtn
                titleButton="Cadastrar categorias de posts"
                :showIcon="false"
                @click="addCategory = true"
              />
            </div> -->
          </div>

          <q-dialog full-height v-model="addCategory">
            <q-card style="width: 50vw">
              <q-separator />

              <q-card-section class="scroll" style="height: 90%">
                <div class="form-input col-6 column">
                  <div class="row">
                    <span class="title-input-form"
                      >Nome da categoria
                      <b title="Obrigatório" style="cursor: pointer">*</b>
                    </span>
                  </div>
                  <div class="row">
                    <q-input
                      class="col-6"
                      bg-color="grey-3"
                      v-model="newCategoryName"
                      color="default-pink"
                      dense
                      type="text"
                      outlined
                      :rules="[(val) => !!val || 'O campo é obrigatório.']"
                    />
                    <div class="col-5 flex items-start justify-end">
                      <DefaultBtn
                        titleButton="adicionar"
                        @click="(event) => addNewCategory(event)"
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <span class="title-input-form">Categoria adicionadas</span>
                </div>
                <div class="form-input col-12 column">
                  <Table
                    class="full-height"
                    :list="listCategories"
                    :columns="columnsTable"
                    :addEdit="false"
                    :addRemove="false"
                    @disabled="(student) => disabled(student)"
                    ><template v-slot:body="props">
                      <div>
                        <q-btn
                          dense
                          round
                          color="default-pink"
                          title="Remover"
                          flat
                          @click="() => disabled(props.props.row)"
                          icon="o_delete_outline"
                        />
                      </div>
                    </template>
                  </Table>
                </div>
              </q-card-section>

              <q-separator />

              <q-card-actions align="right">
                <q-btn flat label="Fechar" color="default-pink" v-close-popup />
              </q-card-actions>
            </q-card>
          </q-dialog>
        </form>
      </div>
    </div>

    <q-inner-loading
      :showing="loading"
      label="Por favor aguarde..."
      label-class="text-teal"
      label-style="font-size: 1.1em"
    />
  </div>
</template>

<script>
import Mural from "@/components/Mural.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import OutlineBtn from "@/components/shared/buttons/OutlineBtn.vue";
import Table from "@/components/shared/tables/TableDynamic.vue";

import { ref, reactive, onMounted } from "vue";
import CompanyServices from "@/services/CompanyServices";
import PostDataServices from "@/services/PostDataServices";

import { useRouter, useRoute } from "vue-router";
import { useQuasar } from "quasar";

export default {
  name: "NewCompany",
  components: {
    Mural,
    DefaultBtn,
    OutlineBtn,
    Table,
  },
  setup() {
    let breadcrumbs = ref(["Adicionar Empresa"]);
    let loading = ref(false);
    let route = new useRoute();
    let router = new useRouter();
    let $q = new useQuasar();
    let _postDataServices = new PostDataServices();
    let companyServices = new CompanyServices();
    let companyId = route.query.companyId;

    let addCategory = ref(false);
    let listCategories = ref([]);
    let newCategoryName = ref("");

    let formCompany = reactive({
      company_name: "",
      cnpj: "",
      phone: "",
      email: "",
      corporate_name: "",
      brand_logo: "",
      user_limit_to_the_expirtation_date: 0,
      no_of_active_user_allowed: 0,
      validity_of_licence: 0,
    });

    let columnsTable = ref([
      {
        name: "label",
        required: true,
        label: "Nome",
        align: "left",
        field: (row) => row.label,
        sortable: true,
      },
      {
        name: "acao",
        align: "center",
        label: "Ações",
        field: "acoes",
        sortable: false,
      },
    ]);

    async function addNewCategory(event) {
      //loading.value = true;
      event.preventDefault();
      if (newCategoryName.value == "") {
        alertErro("Você precisa preencher o campo nome da categoria");
        loading.value = false;
        return;
      }

      const formData = new FormData();
      formData.append("name", newCategoryName.value);

      await _postDataServices
        .addCategories(formData)
        .then(() => {
          listCategories.value.push({
            label: newCategoryName.value,
          });
          alertErro("Categoria adicionada com sucesso", "green-9");
        })
        .catch((error) => {
          alertErro(error.response.data.toString());
        });
      newCategoryName.value = "";
    }

    function disabled(category) {
      $q.notify({
        message: `Deseja remover o categoria ${category.label}?`,
        color: "red-9",
        position: "bottom",
        actions: [
          {
            label: "Remover",
            color: "white",
            handler: () => {
              _removeCategory(category.label);
            },
          },
        ],
      });
    }

    function _removeCategory(label) {
      listCategories.value = listCategories.value.filter((category) => {
        return category.label != label;
      });
    }

    async function saveCompany() {
      loading.value = true;
      if (_validateForm()) {
        alertErro(
          "Para salvar uma empresa você tem que preencher todos os campos obrigatórios."
        );
        return;
      }

/*       if (listCategories.value.length <= 0) {
        alertErro(
          "Você precisa adicionar uma categoria para cadastrar empresa"
        );
        return;
      } */

      if (companyId == null) {
        await companyServices
          .add(formCompany)
          .then(() => {
            router.push({ name: "company" });
            loading.value = false;
          })
          .catch((error) => {
            alertErro(error.response.data.toString());
            loading.value = false;
          });
      } else {
        await companyServices
          .update(companyId, formCompany)
          .then(() => {
            router.push({ name: "company" });
            loading.value = false;
          })
          .catch((error) => {
            alertErro(error.response.data.toString());
            loading.value = false;
          });
      }
    }

    function _validateForm() {
      if (
        formCompany.company_name == "" ||
        formCompany.cnpj == "" ||
        formCompany.phone == "" ||
        formCompany.email == ""
      )
        return true;

      return false;
    }

    async function LoadCompany() {
      if (companyId == null) return null;
      loading.value = true;
      var data = await companyServices.get(companyId);

      formCompany.company_name = data.data.company_name;
      formCompany.cnpj = data.data.cnpj;
      formCompany.phone = data.data.phone;
      formCompany.email = data.data.email;
      formCompany.corporate_name = data.data.corporate_name;
      loading.value = false;
    }

    async function loadCategories() {
      if (companyId == null) {
        listCategories.value = [];
      }
      await _postDataServices.getAllCategories().then((response) => {
        response.data.map((item) => {
          listCategories.value.push({
            label: item.description,
          });
        });
      });
    }

    function alertErro(message, color = "red-9") {
      $q.notify({
        message: message,
        color: color,
        position: "top",
      });
      loading.value = false;
    }
    onMounted(() => {
      LoadCompany();
      loadCategories();
    });

    return {
      formCompany,
      breadcrumbs,
      loading,
      saveCompany,
      addCategory,
      addNewCategory,
      columnsTable,
      listCategories,
      newCategoryName,
      loadCategories,
      disabled,
    };
  },
};
</script>

<style lang="scss" scoped>
.container-options {
  overflow-y: auto;
  height: 86%;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 30px 0px;
}

.title-group {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  opacity: 0.45;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 8px 0px;
}

.title-options {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #000000;
}

.title-status {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  color: #9c9c9c;
}

.title-content {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 17px;
  display: flex;
  padding-left: 20px;
  align-items: center;
  text-transform: capitalize;
  color: #000000;
}

.content-options {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 20px;
  flex: none;
  order: 1;
  height: 50px;
  flex-grow: 0;
  margin: 10px 0px;
}
.content-options:hover {
  background-color: #f3f1f1;
  border: 1px solid #fe5268;
  transition-delay: 0.2ms;
}
.chat-drop {
  background: #ffffff;
  border-bottom: 1px solid #e5e5e5;
  box-sizing: border-box;
}
.btn-drop {
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  width: 100%;
  height: 100%;
}
//.btn-drop:hover{
//  background-color: #FFF8EE;
//  border: 1px solid #FE5268;
//  transition-delay: 0.2ms;
//}
</style>
